import { info } from '~/utils/logging'
export default defineEventHandler((event) => {
  const res = event.node.res

  const url = event.node.req.url
  if (!url) return

  const year = 31536000
  const hour = 60 * 60

  const maxage = url.match(/(.+)\.(jpg|jpeg|gif|css|png|js|ico|svg|mjs)/)
    ? year
    : hour

  info('Setting cache-control header', { url, maxage, res })

  res.setHeader('Cache-Control', `max-age=${maxage} s-maxage=${maxage}`)
})
